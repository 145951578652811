@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
/* common */
body {
  font-family: "Almarai", sans-serif !important;
  color: #18181b !important;
}

::-moz-selection {
  color: #fff;
  background: #5332f8 !important;
}

::selection {
  color: #fff;
  background: #5332f8 !important;
}

ul {
  padding: 0 !important;
}

.container-fluid {
  padding: 0 50px !important;
}

.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.bg-primary {
  background-color: #5332f8 !important;
}

input:focus, input:active, button:focus, button:active, .btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important;
}

/* logo */
.navbar {
  padding: 2rem 0 !important;
  margin: 0 !important;
}
.navbar .primary-logo {
  display: block;
}
.navbar .bwhite-logo {
  display: none;
}

/* Menu */
.nav-link {
  font-size: 17px !important;
  color: #18181b !important;
  padding: 0 !important;
}

.primary-navbar .navbar-nav .dropdown-menu {
  border-radius: 4px !important;
  position: absolute !important;
  top: 30px;
  transition: all 0.2s;
  padding: 10px !important;
  -webkit-animation: dropmove 0.5s;
          animation: dropmove 0.5s;
}
.primary-navbar .navbar-nav .dropdown-menu .dropdown-item {
  font-size: 15px !important;
  padding: 8px 15px !important;
  border-radius: 5px;
}
.primary-navbar .navbar-nav .dropdown-menu .dropdown-item:hover, .primary-navbar .navbar-nav .dropdown-menu .dropdown-item :active, .primary-navbar .navbar-nav .dropdown-menu .dropdown-item :focus {
  outline: none;
  background-color: #5332f8 !important;
  color: #fff;
}

@-webkit-keyframes dropmove {
  from {
    top: 60px;
  }
  to {
    top: 30px;
  }
}

@keyframes dropmove {
  from {
    top: 60px;
  }
  to {
    top: 30px;
  }
}
@-webkit-keyframes dropdown-mob {
  from {
    top: 60px;
  }
  to {
    top: 10px;
  }
}
@keyframes dropdown-mob {
  from {
    top: 60px;
  }
  to {
    top: 10px;
  }
}
.navbar-nav:hover {
  color: #5332f8 !important;
}

.dropdown-header {
  padding: 0 15px;
}
.dropdown-header h6 {
  font-size: 12px !important;
  padding: 8px 0 1px;
}

/* hero */
.hero h2 {
  font-size: 58px !important;
  line-height: 66px;
  font-weight: 1000;
}
.hero h2 span {
  color: #5332f8 !important;
}

.teams {
  position: relative;
}
.teams .team-1 {
  z-index: 1;
}
.teams .team-2 {
  z-index: 2;
  position: absolute;
  left: 30px;
}
.teams .team-3 {
  z-index: 3;
  position: absolute;
  left: 60px;
}

/* body content */
.primary-heading .heading-1 {
  font-size: 52px;
  line-height: 62px;
  font-weight: bold;
  padding-bottom: 40px;
}
.primary-heading .heading-2 {
  font-size: 40px !important;
  line-height: 56px;
  letter-spacing: -2%;
  font-weight: bold;
  padding-bottom: 30px;
}
.primary-heading .heading-3 {
  font-size: 26px !important;
  line-height: 40px;
  letter-spacing: -2%;
  font-weight: bold;
}
.primary-heading .punchline {
  font-size: 16px !important;
  color: #5332f8 !important;
  padding-bottom: 20px;
  font-weight: bold;
}
.primary-heading .para-display {
  font-size: 20px;
  line-height: 32px;
  padding-bottom: 20px;
  font-weight: 500;
}
.primary-heading .para {
  font-size: 16px !important;
  line-height: 30px;
}

.summary {
  margin-top: 30px;
}
.summary .border {
  border: 1px solid rgba(24, 24, 25, 0.2);
  border-width: 1px 0 !important;
}
.summary .summary-list h2 {
  font-size: 46px;
  line-height: 56px;
  font-weight: bold;
}
.summary .summary-list p {
  font-size: 15px;
  line-height: 18px;
  color: rgba(24, 24, 25, 0.6);
}

/* input */
.form-select {
  font-size: 14px !important;
  color: #18181b;
}
.form-select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-select-highlight {
  border: 2px solid #F46A81 !important;
  color: #F46A81;
}

/* button */
.btn {
  border-width: 2px !important;
}

.btn-md {
  border-radius: 5px !important;
  font-size: 15px !important;
  padding: 10px 25px !important;
}

.btn-lg {
  border-radius: 5px !important;
  font-size: 16px !important;
  padding: 12px 45px !important;
}

.btn-primary {
  background-color: #5332f8 !important;
  border-color: #5332f8 !important;
}
.btn-primary:hover {
  background-color: transparent !important;
  border-color: #5332f8 !important;
  color: #5332f8 !important;
}

.btn-primary-outline {
  background-color: transparent !important;
  color: #5332f8 !important;
  border: 2px solid #5332f8 !important;
}
.btn-primary-outline:hover {
  background-color: #5332f8 !important;
  border-color: #5332f8 !important;
  color: #fff !important;
}

.btn-white {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #5332f8 !important;
}
.btn-white:hover {
  background-color: transparent !important;
  border-color: #fff;
  color: #fff !important;
}

/* gap */
.gap {
  padding: 9rem 0;
}

/* Transition */
.slide-up {
  transition: transform 300ms;
  cursor: pointer;
}
.slide-up:hover {
  transform: translate(0, -10px);
}

.zoom-in img {
  width: 100%;
  transition: 0.5s all ease-in-out;
}
.zoom-in:hover img {
  transform: scale(1.1);
}

/* list */
.list-check {
  padding: 0;
}
.list-check li {
  list-style-type: none;
  padding-left: 45px;
  padding-bottom: 20px;
  padding-top: 7px;
  position: relative;
}
.list-check li .icon-check {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 26px !important;
}

/* About */
.about {
  background-image: url('../img/bg-about.png');
  background-position: 10% 80%;
  background-repeat: no-repeat;
  background-color: #5332f8 !important;
  color: #fff;
}
.about .punchline {
  color: #fff !important;
}

/* Testimonials */
.testimonials {
  background-image: url('../img/img-vector-1.png');
  background-position: 90% 30%;
  background-repeat: no-repeat;
}
.testimonials .testimonial {
  padding-right: 50px;
}
.testimonials .testimonial .tst-rate img {
  width: 12px;
}
.testimonials .testimonial .tst-msg {
  padding-top: 15px;
  font-size: 16px !important;
  line-height: 28px;
  font-weight: bold;
}
.testimonials .testimonial .tst-by {
  padding-top: 10px;
}
.testimonials .testimonial .tst-by span {
  color: rgba(24, 24, 27, 0.5);
  padding-left: 5px;
}

/* blog */
.blog {
  position: relative;
  background-color: #f8fafc;
}
.blog:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 319px;
  height: 212px;
  display: block;
  background-image: url('../img/img-vector-2.png');
}
.blog:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 363px;
  height: 240px;
  display: block;
  background-image: url('../img/img-vector-3.png');
}
.blog a {
  text-decoration: none;
}
.blog .card-title {
  font-size: 20px !important;
  line-height: 32px;
  font-weight: bold;
  padding: 20px 0 10px;
  display: block;
  color: #18181b;
}
.blog .card-text {
  color: #5332f8 !important;
  font-size: 13px;
  line-height: 21px;
  display: block;
}

/* contact */
.contact {
  background-color: #F46A81;
  color: #fff;
}
.contact .punchline {
  color: #fff !important;
}
.contact .contact-info {
  margin-top: 10px;
  padding: 20px;
}
.contact .contact-info p {
  padding-top: 10px;
  font-size: 20px !important;
}
.contact .form-control {
  border: none !important;
}

/* newsletter */
.newsletter .form-control:focus {
  border-color: #5332f8 !important;
}

/* form */
#form-message-warning, #form-message-success, #form-message-warning-1, #form-message-success-1 {
  display: none;
}

#form-message-warning, #form-message-warning-1, .error {
  font-size: 15px !important;
}

#form-message-success, #form-message-success-1 {
  color: #28a745;
  font-size: 15px !important;
  font-weight: bold;
}

/* back to top */
#backtop {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5332f8 !important;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  color: #fff;
}
#backtop:hover {
  cursor: pointer;
  background-color: #333;
}
#backtop:active {
  background-color: #555;
}
#backtop.show {
  opacity: 1;
  visibility: visible;
}

/* footer */
.footer {
  position: relative;
  padding: 120px 0 0;
}
.footer h5 {
  margin-bottom: 30px;
  font-size: 14px !important;
  text-transform: uppercase;
  color: rgba(24, 24, 27, 0.8);
}
.footer a {
  text-decoration: none;
  color: #18181b;
}
.footer a :hover {
  color: #5332f8 !important;
}
.footer .ft-text {
  font-size: 14px !important;
  color: rgba(24, 24, 27, 0.7);
}
.footer .copyright {
  font-size: 14px !important;
  border-top: 1px solid rgba(24, 24, 27, 0.15);
  padding-top: 40px;
  margin-top: 20px;
}

/* Responsive */
@media screen and (min-width: 320px) and (max-width: 768px) {
  /* container */
  .container-fluid {
    padding: 0 25px !important;
  }
  /* topmenu */
  .navbar {
    padding: 20px 0 !important;
  }
  .nav-brand {
    margin: 0 auto 20px;
  }
  .nav-link {
    font-size: 15px !important;
  }
  .primary-navbar .navbar-nav .dropdown-menu .dropdown-item {
    font-size: 14px !important;
  }
  .menu-scroll {
    margin: 0 auto;
  }
  /* hero */
  .hero {
    padding: 20px 0 40px;
  }
  .hero h2 {
    font-size: 52px !important;
    line-height: 60px;
  }
  /* common */
  .img-mob {
    width: 100%;
  }
  .gap {
    padding: 6rem 0 5rem;
  }
  /* about */
  .about {
    background-position: 20% 98%;
    background-size: 300%;
  }
  /* testimonials */
  .testimonials {
    background-position: 90% 20%;
  }
  /* blog */
  .blog {
    overflow-y: hidden;
  }
  .blog:before {
    bottom: -120px;
  }
  .blog:after {
    top: -165px;
  }
  /* footer */
  .footer {
    padding: 80px 0 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 820px) {
  /* nav */
  .navbar {
    padding: 30px 0 !important;
  }
  .nav-brand {
    margin: 0;
  }
  .menu-scroll {
    margin: 0;
  }
  /* hero */
  .hero {
    padding: 20px 0 40px;
  }
  .hero h2 {
    font-size: 46px !important;
    line-height: 56px;
  }
  .hero .teams img {
    width: 35px;
  }
  .hero .teams .team-2 {
    left: 20px;
  }
  .hero .teams .team-3 {
    left: 40px;
  }
  /* about */
  .about {
    background-position: 30% 85%;
  }
}
@media screen and (min-width: 900px) {
  /* container */
  .container-fluid {
    padding: 0 80px !important;
  }
  /* hero */
  .hero {
    padding: 20px 0;
  }
  /* footer */
  .footer .copyright {
    font-size: 14px !important;
  }
}/*# sourceMappingURL=main.css.map */