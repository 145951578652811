@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

$color-transparent: transparent !important;
$color-primary: #5332f8 !important;
$color-pink: #F46A81;
$color-white : #fff;
$color-black: #18181b;
$border-sm-radius: 4px !important;
$font-xs: 12px !important;
$font-sm: 14px !important;
$font-md: 24px !important;
$font-lg: 38px !important;
$font-xl: 58px !important;
$font-15: 15px !important;
$font-16: 16px !important;
$font-17: 17px !important;
$font-18: 18px !important;
$font-20: 20px !important;
$font-26: 26px !important;
$font-32: 32px !important;
$font-40: 40px !important;
$line-primary: 66px;
$line-secondary: 28px;
$gap: 9rem 0;

/* common */
body{
  font-family: 'Almarai', sans-serif !important;
  color: $color-black !important;
}
::selection {
  color: $color-white;
  background: $color-primary;
}
ul{
  padding: 0 !important;
}
.container-fluid{
  padding: 0 50px !important;
}
.wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
}
.bg-primary{
  background-color: $color-primary;
}

input:focus,input:active,button:focus,button:active,.btn:focus,.btn:active{
  outline: none !important;
  box-shadow: none !important;
}
 
/* logo */
.navbar{
  padding: 2rem 0 !important;
  margin: 0 !important;
  .primary-logo{
    display: block;
  }
  .bwhite-logo{
    display: none;
  }
}

/* Menu */
.nav-link{
  font-size: $font-17;
  color:$color-black !important;
  padding: 0 !important;
}
.primary-navbar {
  .navbar-nav  {
    .dropdown-menu{
      border-radius:$border-sm-radius;
      position: absolute !important;
      top:30px;
      transition: all 0.2s;
      padding: 10px !important;
      animation: dropmove 0.5s;

      .dropdown-item{
          font-size: $font-15;
          padding: 8px 15px !important;
          border-radius: 5px;
          &:hover,:active,:focus{
              outline: none;
              background-color: $color-primary;
              color: $color-white;
          }

      }
    }
  }  
}

@keyframes dropmove {
    from {top: 60px;}
    to {top: 30px;}
  }
@keyframes dropdown-mob {
  from {top: 60px;}
  to {top: 10px;}
}
   
.navbar-nav{
    &:hover{
        color: $color-primary;
    }
}  

.dropdown-header{
    padding: 0 15px;
    h6{
    font-size: 12px !important;
    padding: 8px 0 1px;
    }
}

/* hero */
.hero{
  h2{
  font-size: $font-xl;
  line-height: $line-primary;
  font-weight: 1000;
  span{
    color: $color-primary;
  }  
  }
}
.teams{
  position: relative;
   .team-1{
    z-index:1;
   }
   .team-2{
    z-index:2;
    position: absolute;
    left: 30px;
   }
   .team-3{
    z-index:3;
    position: absolute;
    left: 60px;
   }
}
 
/* body content */
.primary-heading{
  .heading-1{
    font-size: 52px;
    line-height: 62px;
    font-weight: bold;
    padding-bottom: 40px;
  }
  .heading-2{
    font-size: $font-40;
    line-height: 56px;
    letter-spacing: -2%;
    font-weight:bold;
    padding-bottom: 30px;
  }
  .heading-3{
    font-size: $font-26;
    line-height: 40px;
    letter-spacing: -2%;
    font-weight:bold;
  }
  .punchline{
    font-size: $font-16;
    color:$color-primary;
    padding-bottom: 20px;
    font-weight: bold;
  }
  .para-display{
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 20px;
    font-weight: 500;
  }
  .para{
    font-size: $font-16;
    line-height: 30px;
  }
}

.summary{
  margin-top: 30px;
  .border{
    border: 1px solid rgba(24,24,25,0.2);
    border-width:1px 0 !important;
  }
  .summary-list{
    h2{
      font-size: 46px;
      line-height: 56px;
      font-weight: bold;
    }
    p{
      font-size: 15px;
      line-height: 18px;
      color: rgba(24,24,25,0.6);
    }
  }
} 


/* input */
.form-select{
  font-size: $font-sm;
  color: $color-black;
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
}
.form-select-highlight{
  border:2px solid  $color-pink !important;
  color: $color-pink;
}


/* button */
.btn{
  border-width: 2px !important;
}
.btn-md{
  border-radius: 5px !important;
  font-size: $font-15 !important;
  padding: 10px 25px !important;
}
.btn-lg{
  border-radius: 5px !important;
  font-size: $font-16;
  padding: 12px 45px !important;
}
.btn-primary{
  background-color: $color-primary ;
  border-color: $color-primary ;
  &:hover{
    background-color: $color-transparent;
    border-color: $color-primary;
    color: $color-primary;
  }
 } 
.btn-primary-outline{
  background-color: $color-transparent;
  color: $color-primary;
  border:2px solid $color-primary;
  &:hover{
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white !important;
  }
 } 
 .btn-white{
  background-color: $color-white !important ;
  border-color: $color-white !important;
  color:$color-primary;
  &:hover{
    background-color: $color-transparent;
    border-color: $color-white;
    color: $color-white !important;
  }
 } 

 /* gap */
.gap{
  padding: $gap;
}
/* Transition */
.slide-up{
  transition: transform 300ms;
  cursor: pointer;
  &:hover{
    transform: translate(0, -10px);
  }
}
.zoom-in{
  img{  
  width: 100%;
  transition: 0.5s all ease-in-out;
  }
  &:hover{
   img{ 
    transform: scale(1.1);
  }
  }
}

/* list */
.list-check{
  padding: 0;
  li{
    list-style-type: none;
    padding-left: 45px;
    padding-bottom: 20px;
    padding-top: 7px;
    position: relative;
    .icon-check{
      position: absolute;
      left: 0;
      top: 0;
      font-size: $font-26;
    }
  }
}

/* About */
.about{
  background-image: url(../img/bg-about.png);
  background-position: 10% 80%;
  background-repeat: no-repeat;
  background-color: $color-primary;
  color: $color-white;
  .punchline{
    color: $color-white !important;
  }
} 

/* Testimonials */
.testimonials{
  background-image: url(../img/img-vector-1.png);
  background-position: 90% 30%;
  background-repeat: no-repeat;
  .testimonial{
    padding-right: 50px;
    .tst-rate{
      img{
        width: 12px;
      } 
    }
    .tst-msg{
      padding-top: 15px;
      font-size: $font-16;
      line-height: 28px;
      font-weight: bold;
      }
    .tst-by{
      padding-top: 10px; 
      span{
        color: rgba($color-black, 0.5 );
        padding-left: 5px;
      }
    }
  }
}


/* blog */
.blog{
  position: relative;
  &:before{
    content: '';
    position: absolute;
    bottom: 0;
    width: 319px;
    height: 212px;
    display: block;
    background-image: url(../img/img-vector-2.png);
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 363px;
    height: 240px;
    display: block;
    background-image: url(../img/img-vector-3.png);

  }
  background-color: #f8fafc;
  a{
    text-decoration: none;
  }
  .card-title{
    font-size: $font-20;
    line-height: 32px;
    font-weight: bold;
    padding: 20px 0 10px;
    display: block;
    color: $color-black;
  }
  .card-text{
    color: $color-primary;
    font-size: 13px;
    line-height: 21px;
    display: block;
  }
}

/* contact */
.contact{
  background-color: $color-pink;
  color:$color-white;
  .punchline{
    color:$color-white !important;
  }
  .contact-info{
    margin-top: 10px;
    padding: 20px;
    p{
      padding-top: 10px;
      font-size: $font-20;
    }
  }
  .form-control{
    border: none !important;
  }
}

/* newsletter */
.newsletter{  
  .form-control{
    &:focus{
      border-color:$color-primary;
    }
  }
}

/* form */
#form-message-warning, #form-message-success,#form-message-warning-1, #form-message-success-1 {
  display: none;  }
#form-message-warning,#form-message-warning-1,.error {font-size: $font-15;}
#form-message-success,#form-message-success-1 {
  color: #28a745;
  font-size: $font-15;
  font-weight: bold; 
}


/* back to top */
#backtop {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color .3s, 
  opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  color: $color-white;
 &:hover {
  cursor: pointer;
  background-color: #333;
  }
  &:active {
  background-color: #555;
  }
  &.show {
  opacity: 1;
  visibility: visible;
  }
}

/* footer */
.footer{
  position: relative;
  padding: 120px 0 0;
  h5{
    margin-bottom: 30px;
    font-size: $font-sm;
    text-transform: uppercase;
    color: rgba($color-black, 0.8);
  }
  a{
    text-decoration: none;
    color: $color-black;
    :hover{
      color: $color-primary;
    }
  }
  .ft-text{
    font-size: $font-sm;
    color: rgba($color-black, 0.7);
  }
  .copyright{
    font-size: $font-sm;
    border-top: 1px solid rgba($color-black, 0.15);
    padding-top: 40px;
    margin-top: 20px;
  }
}

/* Responsive */
@media screen and (min-width: 320px) and (max-width: 768px) {
/* container */
  .container-fluid{
        padding: 0 25px !important;
  }

/* topmenu */
 
.navbar{
  padding: 20px 0 !important;
}
.nav-brand{
  margin: 0 auto 20px;
}
.nav-link{
  font-size: $font-15; 
}
.primary-navbar .navbar-nav .dropdown-menu .dropdown-item{
  font-size: $font-sm;
}
.menu-scroll{
  margin: 0 auto;
}
/* hero */
 .hero{
    padding: 20px 0 40px;
    h2{
      font-size: 52px !important;
      line-height: 60px;
    }
    
  }
 /* common */ 
  .img-mob{
    width: 100%;
  }
  .gap{
    padding: 6rem 0 5rem;
  }

/* about */  
  .about{
    background-position: 20% 98%;
    background-size: 300%;
  }

/* testimonials */
  .testimonials{
    background-position: 90% 20%;
  }

/* blog */
  .blog{
    overflow-y: hidden;
  }
  .blog:before{
    bottom: -120px;    
  }
  .blog:after{
    top: -165px;    
  }

 /* footer */
  .footer{
    padding: 80px 0 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 820px) {
  /* nav */
  .navbar{
    padding: 30px 0 !important;
  }
  .nav-brand{
    margin: 0;
  }
  .menu-scroll{
    margin: 0;
  }

 /* hero */
 .hero{
  padding: 20px 0 40px;
  h2{
    font-size: 46px !important;
    line-height: 56px;
  }
  .teams{
    img{ width: 35px;}
    .team-2{
      left: 20px;
    }
    .team-3{
      left: 40px;
    }
  }
  
}
/* about */  
.about{
  background-position: 30% 85%;
}
} 

@media screen and (min-width: 900px) {
 /* container */  
  .container-fluid{
    padding: 0 80px !important;
  }

  /* hero */ 
  .hero{
    padding: 20px 0;
  }
 
/* footer */ 
  .footer{
    .copyright{
      font-size: $font-sm;
    }
  }
}